<template>
  <div class="row">
    <modal name="open_to_settings_table" :adaptive="true" height="auto" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable class="list-group py-4" tag="ul" v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove"
            @start="isDragging=true" @end="isDragging=false">
            <transition-group type="transition" :name="'flip-list'">
              <div class="col-12 list-group-item w-300px d-flex justify-content-between "
                v-for="element in fieldsToViewEdit" :key="element.sort">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input type="checkbox" style="font-size: 10px " v-model="element.type_status"></span>
              </div>
            </transition-group>
          </draggable>
          <button @click="closeModal"
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button @click="onSubmitToSaveSettingTable"
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal>
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div class="row py-3 px-4" id="filter-card" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" title="general.search"
                  :placeholder="$t('general.search_filter')" :model.sync="filters.search"></input-filter>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0 px-1">
                <div class="col-md-12 my-md-0">
                  <select-filter :helper-text="$t('general.status_filter')" title="general.status_filter"
                    :model.sync="filters.selectedWaybillType" :values="waybillTypes" width="150px" :item-per-row="1">
                  </select-filter>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" title="general.start_date"
                    :model.sync="filters.start_date" name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" title="general.end_date"
                    :model.sync="filters.end_date" name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click.prevent="filterResult(null)"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>

                <div class="col-12 pr-0" v-if="false">
                  <button @click="logoSync"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.logo_sync') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <dashboard-box :title="$t('waybill.waybill_list')">
        <template v-slot:preview>
          <template v-if="filters">
            <div class="d-flex justify-content-center items-center">
              <div class="w-60px h-60px" @click="$router.push({name: 'waybill.exit.new'})"
                v-if="isUserGranted('Waybill', ['create'], false)">
                <span v-html="getIconByKey('icons.waybill.waybill_exit', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
              <top-waybill-nav style="flex-grow: 1;" :model.sync="filters.current_waybill_type" :data="storeData">
              </top-waybill-nav>
              <div class="w-60px h-60px" @click="$router.push({name: 'waybill.entry.new', params: {type: 1}})"
                v-if="isUserGranted('Waybill', ['create'], false)">
                <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>
            <second-waybill-nav :model.sync="currentDepoType" :parentId="filters.current_waybill_type"
              :data="storeData"></second-waybill-nav>
            <div class="fixed" style="position: fixed !important; top: 370px; right: 15px;">
              <div class="w-60px h-60px" @click="openPopToSettingTable">
                <span v-html="getIconByKey('icons.waybill.edit', {
                  class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>
          </template>

          <data-table 
              tableVariant="''" 
              :hover="false" 
              :outlined="false" 
              :striped="false" 
              :no-border-collapse="true"
              :borderless="true" 
              :bordered="false" 
              @hitBottom="onHitBottom" 
              @onApproveReject="onApproveReject"
              @onChangeSearchFilter="onChangeSearchFilter"
              @rowClicked="onRowClicked" 
              :items="formattedWaybill" 
              :fields.sync="tableFields" 
              :infiniteId="infiniteId"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              >
          </data-table>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import { GET_ITEMS, HANDLE_INFINITE_SCROLL, LOADING, UPDATE_ITEM_BY_ID, FILTER, SET_FILTER } from "@/core/services/store/waybill/waybill.module";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import {WAYBILL_INDEX_END_DATE_STORAGE_NAME, WAYBILL_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

import * as _ from "lodash";
import draggable from 'vuedraggable'

import waybillStore from "@/core/services/services/waybillStore";
export default {
  name: "WaybillIndex",
  mixins: [waybillStore],
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    draggable
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    formattedWaybill() {
      if (!this.waybills || this.waybills.data === null || this.waybills.data === undefined) return [];
      let temp = [];
      this.waybills.data.forEach(tempCurrent => {
        let unit = tempCurrent.transactions && tempCurrent.transactions.length ? tempCurrent.transactions[0].unit.code : '';
        temp.push({
          'id': tempCurrent.id,
          'waybill_action_type': tempCurrent.transaction_type,
          'created_date': moment(tempCurrent.transaction_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'company_name': tempCurrent.supplier_company.name,
          'waybill_type': tempCurrent.waybill_type.translations[0].name,
          'document_no': tempCurrent.document_number,
          'order_no': tempCurrent.order_number,
          'amount': tempCurrent.transactions_net_amount + ' ' + unit,
          'approve_status': tempCurrent.approve_status,
        })
      })
      return temp;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          fields.push({
            'key': key,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'label': this.$t(value.name),
            'sortable': value.status,
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    },
    storeData() {
      if (this.storeAllItems) {
        return this.storeAllItems;
      }
      return [];
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      search: null,
      paginate: [],
      page: 1,
      perPage: 25,
      sort: "desc",
      order: "id",
      currentWaybillType: 1,
      currentDepoType: 3,
      selectedWaybillType: null,
      startDate: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),

      waybills: { data: [] },
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      waybillTypes: {
        "0": "Çıkış",
        "1": "Giriş",
      },
      storeUrl: 'api/stores',
      logoSyncUrl: 'api/waybills/logo/sync',
      waybillListUrl: 'api/waybills-index'
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
    }),
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'waybill'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
          console.log('new')
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
        }
      }
      let contents = {
        model_name: 'waybill',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          console.log(result.status)
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    filterResult() {
      localStorage.setItem(WAYBILL_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(WAYBILL_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;

      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);


      if (self.isUserGranted('Waybill', ['viewAny'])) {
        self.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.waybillListUrl,
          filters: filters,
          '$state': $state,
        }).then(result => {
          if (result.status) {
            self.waybills = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }

            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        })
      }
    },
    resetFilters() {
      this.sort="desc";
      this.order= "id";
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        status: null,
        search: null,
        order_status_id: null,
        page: 1,
        per_page: 25,
        transaction_type: null,
        store_id: null,
        current_waybill_type: 1,
        current_depo_type: 3,
      })
    },
    logoSync() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.logoSyncUrl,
        acceptPromise: true,
        filters: {}
      }).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_logo_updated')).then(_ => {
            self.onHitBottom();
          })
        }
      });
    },
    onRowClicked(item, index, event) {
      this.$router.push({ name: 'waybill.detail', params: { id: this.waybills.data[index].id, } });
    },
    onApproveReject(data) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure"), function (self) {
        let payload = {
          url: 'api/waybills/approve_status/' + data.id,
          contents: { approve_status: data.status },
        }
        self.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(response => {
          if (response.status) {
            self.sweetAlertSuccess(self.$t('general.successfully_saved')).then((res) => {
              self.onHitBottom(null);
            })
            self.sweetAlertSuccess("");
          } else {
            self.sweetAlertError(self.$t('general.internal_error'));
          }
        });
      }, () => {
      })
    },
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("waybill.waybills") }
    ]);
    let self = this;

    if (this.isUserGranted('Waybill', ['viewAny'])) {
      
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'waybill'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
        }
      });

      if (!this.filter) {
        this.resetFilters();
      }
      else {
        this.currentDepoType = this.filter.current_depo_type;
      }

      this.filters.end_date = localStorage.hasOwnProperty(WAYBILL_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(WAYBILL_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

      this.filters.start_date = localStorage.hasOwnProperty(WAYBILL_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(WAYBILL_INDEX_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

      setTimeout(this.onHitBottom, 1000);
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    currentDepoType(newValue) {
      this.infiniteId++;
      this.page = 1;
      this.$set(this.filters, 'store_id', newValue)
      this.$set(this.filters, 'current_depo_type', newValue)
      setTimeout(() => {
        this.onHitBottom();
      }, 100);
    }
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
